import type {GaugeV1, MetricsConstructorOptions} from '@deezer/logcenter';
import {RECLog, RECLogContext} from '@deezer/logcenter';
import {LogType} from './constants';

export type GaugePayload = MetricsConstructorOptions & {
	tags: Record<string, string>;
	value: number;
};

const recLogContext = new RECLogContext({
	name: 'standalone-landing',
	version: '1.0.0',
});

class GaugeValueMetrics extends RECLog<GaugeV1> {
	#id: string;
	#tags?: Record<string, string>;
	#value: number;

	constructor(params: GaugePayload) {
		super({
			type: LogType.Gauge,
			version: '1.0.0',
			...recLogContext,
		});

		this.#id = params.id;
		this.#value = params.value;

		if ('tags' in params) {
			this.#tags = params.tags;
		}
	}

	serialize() {
		return {
			...super.serialize(),
			metric: {
				id: this.#id,
				tags: this.#tags,
			},
			gauge: this.#value,
		};
	}
}

export default GaugeValueMetrics;
