import GaugeValueMetrics from '@app/modules/tracking/log/gaugeMetrics';
import {logCenter} from '@app/modules/tracking/logCenter';
import {useUser} from '@deezer/react-user';
import {usePathname} from 'next/navigation';
import {useRouter} from 'next/router';
import {useReportWebVitals} from 'next/web-vitals';
import {useMemo} from 'react';

const PERF_LOGGING_SAMPLING = process.env.NODE_ENV === 'development' ? 1 : 100;

export const usePerformanceMonitoring = () => {
	const [user] = useUser();
	const {locale} = useRouter();
	const pathname = usePathname();
	const shouldLog = useMemo(
		() => Math.floor(Math.random() * PERF_LOGGING_SAMPLING) === 0,
		[],
	);

	useReportWebVitals((metric) => {
		// Calculated at max once per page
		if (!shouldLog) return;

		// 2 decimal
		let value = parseFloat(metric.value?.toFixed(2));

		// convert to s
		if (['LCP', 'FCP'].includes(metric.name)) {
			value = Math.round(metric.value / 10) / 100;
		}

		logCenter.log(
			new GaugeValueMetrics({
				id: 'standalone.core.web.vitals',
				tags: {
					name: metric.name,
					url: pathname ?? '/',
					rating: metric.rating,
					locale,
					...(user?.country && {country: user.country}),
				},
				value,
			}).serialize(),
		);
	});
};
